import React, { useState, useEffect, useContext } from "react";
import { getFirestore, collection, getDocs, doc, deleteDoc, query, orderBy, limit, startAfter, addDoc, where } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";
import Modal from "react-modal";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import './css/styles.css';

const Discounts = () => {
    const [categories, setCategories] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDiscountDeleteModalOpen, setIsDiscountDeleteModalOpen] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState("");
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    const [discountToDelete, setDiscountToDelete] = useState(null);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [discounts, setDiscounts] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const { currentUser, isSuperadmin } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser) {
            if (isSuperadmin) {
                fetchCategories(); // Fetch categories only if user is superadmin
                fetchInitialDiscounts(); // Fetch discounts only if user is superadmin
            }
        } else {
            navigate("/login"); // Redirect to login if no user is logged in
        }
    }, [currentUser, isSuperadmin, navigate]);

    const fetchCategories = async () => {
        try {
            const db = getFirestore();
            const categoriesCollection = collection(db, "discount_categories");
            const categoriesSnapshot = await getDocs(categoriesCollection);
            const categoriesList = categoriesSnapshot.docs.map(doc => ({
                id: doc.id,
                name: doc.data().name
            }));
            setCategories(categoriesList);
        } catch (error) {
            console.error("Error fetching categories: ", error);
        }
    };

    const fetchInitialDiscounts = async () => {
        try {
            setLoading(true);
            const db = getFirestore();
            const discountsCollection = collection(db, "discounts");
            const discountsQuery = query(discountsCollection, orderBy("title_lowercase"), limit(50));

            const discountsSnapshot = await getDocs(discountsQuery);
            const discountsList = discountsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setDiscounts(discountsList);
            setLastVisible(discountsSnapshot.docs[discountsSnapshot.docs.length - 1]);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching discounts: ", error);
            setLoading(false);
        }
    };

    const fetchDiscounts = async (nextPage = false, isSearch = false) => {
        try {
            setLoading(true);
            const db = getFirestore();
            const discountsCollection = collection(db, "discounts");
            let discountsQuery;

            if (isSearch && searchQuery.trim() !== "") {
                const lowerCaseSearchQuery = searchQuery.toLowerCase();
                discountsQuery = query(
                    discountsCollection,
                    where("title_lowercase", ">=", lowerCaseSearchQuery),
                    where("title_lowercase", "<=", lowerCaseSearchQuery + "\uf8ff"),
                    orderBy("title_lowercase"),
                    limit(1000)
                );
            } else {
                discountsQuery = query(discountsCollection, orderBy("title_lowercase"), limit(50));
                if (nextPage && lastVisible) {
                    discountsQuery = query(discountsCollection, orderBy("title_lowercase"), startAfter(lastVisible), limit(50));
                }
            }

            const discountsSnapshot = await getDocs(discountsQuery);
            const discountsList = discountsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            if (isSearch) {
                setDiscounts(discountsList);
            } else {
                setDiscounts(nextPage ? [...discounts, ...discountsList] : discountsList);
            }
            setLastVisible(discountsSnapshot.docs[discountsSnapshot.docs.length - 1]);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching discounts: ", error);
            setLoading(false);
        }
    };

    const handleSearch = async () => {
        fetchDiscounts(false, true);
    };

    const openAddModal = () => {
        setIsAddModalOpen(true);
    };

    const closeAddModal = () => {
        setIsAddModalOpen(false);
    };

    const openDeleteModal = (category) => {
        setCategoryToDelete(category);
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setCategoryToDelete(null);
        setIsDeleteModalOpen(false);
    };

    const openDiscountDeleteModal = (discount) => {
        setDiscountToDelete(discount);
        setIsDiscountDeleteModalOpen(true);
    };

    const closeDiscountDeleteModal = () => {
        setDiscountToDelete(null);
        setIsDiscountDeleteModalOpen(false);
    };

    const handleSaveCategory = async () => {
        if (newCategoryName.trim() === "") {
            alert("Category name cannot be empty.");
            return;
        }

        try {
            const db = getFirestore();
            const categoriesCollection = collection(db, "discount_categories");

            await addDoc(categoriesCollection, {
                name: newCategoryName.trim()
            });

            // Refresh categories list
            fetchCategories();
            setNewCategoryName("");
            closeAddModal();
        } catch (error) {
            console.error("Error adding category: ", error);
        }
    };

    const handleDeleteCategory = async () => {
        if (!categoryToDelete) return;

        try {
            const db = getFirestore();
            const discountsCollection = collection(db, "discounts");

            // Check if any discounts are associated with this category
            const discountsQuery = query(discountsCollection, where("categories", "array-contains", categoryToDelete.id));
            const discountsSnapshot = await getDocs(discountsQuery);

            if (discountsSnapshot.size > 0) {
                alert("Tu peux pas ! Y a des discounts dans cette catégorie.");
                closeDeleteModal();
                return;
            }

            // No discounts are associated, proceed to delete the category
            const categoryDocRef = doc(db, "discount_categories", categoryToDelete.id);
            await deleteDoc(categoryDocRef);

            // Refresh categories list
            fetchCategories();
            closeDeleteModal();
        } catch (error) {
            console.error("Error deleting category: ", error);
        }
    };

    const handleDeleteDiscount = async () => {
        if (!discountToDelete) return;

        try {
            const db = getFirestore();
            const storage = getStorage();
            const discountDocRef = doc(db, "discounts", discountToDelete.id);

            // Delete the associated image from Firebase Storage
            const imageRef = ref(storage, discountToDelete.image_url);
            await deleteObject(imageRef);

            // Delete the discount document from Firestore
            await deleteDoc(discountDocRef);

            // Refresh discount list
            fetchDiscounts();
            closeDiscountDeleteModal();
        } catch (error) {
            console.error("Error deleting discount: ", error);
            alert("Failed to delete discount or associated image.");
        }
    };

    const toggleAccordion = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };

    const loadMoreDiscounts = () => {
        fetchDiscounts(true);
    };

    return (
        <div className="container">
            {isSuperadmin ? (
                <>
                    <h2>
                        <button onClick={toggleAccordion} className="accordion">
                            Catégories de discount
                            <span>{isAccordionOpen ? " ▲ " : " ▼ "}</span>
                        </button>
                    </h2>

                    <div className={`panel ${isAccordionOpen ? 'open' : ''}`}>
                        <div className="categories-list">
                            {categories.map(category => (
                                <div key={category.id} className="category-item">
                                    {category.name}
                                    <button onClick={() => openDeleteModal(category)} className="delete-button">×</button>
                                </div>
                            ))}
                        </div>

                        <button onClick={openAddModal} className="add-button">+</button>
                    </div>

                    {/* Search Field */}
                    <div className="search-container">
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Rechercher un discount"
                            className="search-input"
                        />
                        <button onClick={handleSearch} className="search-button">Chercher</button>
                    </div>

                    {/* Discount Listing */}
                    <div className="discount-list">
                        {discounts.map(discount => (
                            <div key={discount.id} className="discount-item">
                                <a href={discount.link} target="_blank" rel="noopener noreferrer">
                                    <img src={discount.image_url} alt={discount.title} />
                                </a>
                                <h3>{discount.title}</h3>
                                <p>Code: {discount.code}</p>
                                <p>Début: {new Date(discount.start_date.seconds * 1000).toLocaleDateString()}</p>
                                <p>fin: {new Date(discount.end_date.seconds * 1000).toLocaleDateString()}</p>
                                {/* <p>Vues: {discount.views}</p>
                                <p>Clics: {discount.clicks}</p> */}
                                <button onClick={() => openDiscountDeleteModal(discount)} className="delete-discount-button">×</button>
                            </div>
                        ))}
                    </div>

                    {/* Pagination */}
                    <div className="pagination">
                        {loading ? (
                            <p>Chargement...</p>
                        ) : (
                            <button onClick={loadMoreDiscounts} className="load-more-button">Charger plus</button>
                        )}
                    </div>

                    {/* Add Category Modal */}
                    <Modal
                        isOpen={isAddModalOpen}
                        ariaHideApp={false}
                        onRequestClose={closeAddModal}
                        contentLabel="Add Category"
                        style={modalStyles}
                    >
                        <h2>Ajouter une catégorie</h2>
                        <input
                            className="category-input"
                            type="text"
                            value={newCategoryName}
                            onChange={(e) => setNewCategoryName(e.target.value)}
                            placeholder="Nom de la catégorie"
                        />
                        <button onClick={handleSaveCategory} className="save-button">Enregistrer</button>
                        <button onClick={closeAddModal} className="cancel-button">Annuler</button>
                    </Modal>

                    {/* Delete Category Confirmation Modal */}
                    <Modal
                        isOpen={isDeleteModalOpen}
                        ariaHideApp={false}
                        onRequestClose={closeDeleteModal}
                        contentLabel="Delete Category"
                        style={modalStyles}
                    >
                        <h2>T'es sûr que tu veux la dégager ?</h2>
                        <button onClick={handleDeleteCategory} className="save-button">ben oui</button>
                        <button onClick={closeDeleteModal} className="cancel-button">oups non</button>
                    </Modal>

                    {/* Delete Discount Confirmation Modal */}
                    <Modal
                        isOpen={isDiscountDeleteModalOpen}
                        ariaHideApp={false}
                        onRequestClose={closeDiscountDeleteModal}
                        contentLabel="Delete Discount"
                        style={modalStyles}
                    >
                        <h2>T'es sûr de ton coup ? On le supprime ?</h2>
                        <button onClick={handleDeleteDiscount} className="save-button">Vazy</button>
                        <button onClick={closeDiscountDeleteModal} className="cancel-button">Nan</button>
                    </Modal>
                </>
            ) : (
                <p>Vous n'avez pas accès à cette page.</p>
            )}
        </div>
    );
};

const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        padding: "30px",
        width: "100%",
        maxWidth: "400px",
        borderRadius: "15px",
        backgroundColor: "#fff",
        boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
        border: "none",
    },
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
};

export default Discounts;
