import React, { createContext, useState, useEffect } from "react";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { auth, firestore } from "./firebase";
import { doc, getDoc } from "firebase/firestore";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(() => {
        const savedUser = localStorage.getItem("currentUser");
        return savedUser ? JSON.parse(savedUser) : null;
    });
    const [isSuperadmin, setIsSuperadmin] = useState(false);

    const login = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Fetch the user's Firestore document by user ID
            const userDocRef = doc(firestore, "users", user.uid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                const userData = userDoc.data();
                if (userData.is_creator) {
                    // User is a content creator, proceed with login
                    setCurrentUser(user);
                    setIsSuperadmin(!!userData.is_superadmin); // Store superadmin status
                    localStorage.setItem("currentUser", JSON.stringify(user));
                    return true;
                } else {
                    return "Cette plateforme est reservee aux createurs de contenu.";
                }
            } else {
                return "User data not found.";
            }
        } catch (error) {
            console.error("Error during login:", error);
            return false;
        }
    };

    const logout = () => {
        auth.signOut();
        setCurrentUser(null);
        setIsSuperadmin(false); // Reset superadmin status on logout
        localStorage.removeItem("currentUser");
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setCurrentUser(user);
                localStorage.setItem("currentUser", JSON.stringify(user));

                // Fetch the user's Firestore document by user ID
                const userDocRef = doc(firestore, "users", user.uid);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setIsSuperadmin(!!userData.is_superadmin); // Store superadmin status
                }
            } else {
                setCurrentUser(null);
                setIsSuperadmin(false); // Reset superadmin status
                localStorage.removeItem("currentUser");
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <AuthContext.Provider value={{ currentUser, isSuperadmin, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
