import React, { useState, useEffect, useContext } from "react";
import { getFirestore, collection, getDocs, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import './css/styles.css';

const generateUniqueFilename = () => {
    const timestamp = Date.now();
    const randomString = Math.random().toString(36).substring(2, 15);
    return `${timestamp}_${randomString}.jpg`;
};

const AddDiscount = () => {
    const [categories, setCategories] = useState([]);
    const [newDiscountTitle, setNewDiscountTitle] = useState("");
    const [newDiscountLink, setNewDiscountLink] = useState("");
    const [newDiscountCode, setNewDiscountCode] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [imageFile, setImageFile] = useState(null);
    const { currentUser, isSuperadmin } = useContext(AuthContext);
    const navigate = useNavigate();
    const [hasFetched, setHasFetched] = useState(false);

    useEffect(() => {
        if (currentUser) {
            if (isSuperadmin && !hasFetched) {
                fetchCategories(); // Fetch categories on initial load if the user is a superadmin
                setHasFetched(true);
            }
        } else {
            navigate("/login"); // Redirect to login if no user is logged in
        }
    }, [currentUser, isSuperadmin, hasFetched, navigate]);

    const fetchCategories = async () => {
        try {
            const db = getFirestore();
            const categoriesCollection = collection(db, "discount_categories");
            const categoriesSnapshot = await getDocs(categoriesCollection);
            const categoriesList = categoriesSnapshot.docs.map(doc => ({
                id: doc.id,
                name: doc.data().name
            }));
            setCategories(categoriesList);
        } catch (error) {
            console.error("Error fetching categories: ", error);
        }
    };

    const handleImageResize = (file, callback) => {
        const img = new Image();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        img.onload = () => {
            canvas.width = 900;
            canvas.height = 600;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            canvas.toBlob(
                (blob) => {
                    const resizedFile = new File([blob], "resized_image.jpg", {
                        type: "image/jpeg",
                    });
                    callback(resizedFile);
                },
                "image/jpeg",
                1
            );
        };
        img.src = URL.createObjectURL(file);
    };

    const handleAddDiscount = async () => {
        if (
            newDiscountTitle.trim() === "" ||
            newDiscountLink.trim() === "" ||
            startDate === "" ||
            endDate === "" ||
            selectedCategories.length === 0 ||
            !imageFile
        ) {
            alert("Please fill in all fields, select at least one category, and upload an image.");
            return;
        }

        handleImageResize(imageFile, async (resizedImage) => {
            try {
                const db = getFirestore();
                const storage = getStorage();
                const discountsCollection = collection(db, "discounts");

                const uniqueFilename = generateUniqueFilename();

                // Upload the resized image to Firebase Storage
                const imageRef = ref(storage, `discount_images/${uniqueFilename}`);
                const snapshot = await uploadBytes(imageRef, resizedImage);
                const imageUrl = await getDownloadURL(snapshot.ref);

                const newDiscount = {
                    title: newDiscountTitle,
                    title_lowercase: newDiscountTitle.toLowerCase(),
                    link: newDiscountLink,
                    code: newDiscountCode || "",
                    start_date: new Date(startDate),
                    end_date: new Date(endDate),
                    categories: selectedCategories,
                    image_url: imageUrl,
                    views: 0,
                    clicks: 0,
                };

                await addDoc(discountsCollection, newDiscount);
                // Reset state to trigger a re-render and refetch categories
                resetForm();
                alert("Discount added successfully!");
            } catch (error) {
                console.error("Error adding discount: ", error);
            }
        });
    };

    const resetForm = () => {
        setNewDiscountTitle("");
        setNewDiscountLink("");
        setNewDiscountCode("");
        setStartDate("");
        setEndDate("");
        setSelectedCategories([]);
        setImageFile(null);
        setHasFetched(false); // Allow refetching categories after successful save
    };

    const handleCategoryChange = (e) => {
        const value = e.target.value;
        setSelectedCategories(
            selectedCategories.includes(value)
                ? selectedCategories.filter((category) => category !== value)
                : [...selectedCategories, value]
        );
    };

    return (
        <div className="add-discount-container">
            {isSuperadmin ? (
                <>
                    <h2>Ajoute un nouveau discount</h2>
                    <input
                        type="text"
                        value={newDiscountTitle}
                        onChange={(e) => setNewDiscountTitle(e.target.value)}
                        placeholder="Titre"
                        className="discount-input"
                    />
                    <input
                        type="text"
                        value={newDiscountLink}
                        onChange={(e) => setNewDiscountLink(e.target.value)}
                        placeholder="Lien"
                        className="discount-input"
                    />
                    <input
                        type="text"
                        value={newDiscountCode}
                        onChange={(e) => setNewDiscountCode(e.target.value)}
                        placeholder="Code (Optionel)"
                        className="discount-input"
                    />
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="discount-input"
                    />
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="discount-input"
                    />
                    <div className="categories-checkboxes">
                        {categories.map((category) => (
                            <div key={category.id} className="category-checkbox">
                                <input
                                    type="checkbox"
                                    id={category.id}
                                    value={category.id}
                                    checked={selectedCategories.includes(category.id)}
                                    onChange={handleCategoryChange}
                                />
                                <label htmlFor={category.id}>{category.name}</label>
                            </div>
                        ))}
                    </div>
                    <input
                        type="file"
                        onChange={(e) => setImageFile(e.target.files[0])}
                        accept=".jpg"
                        className="discount-input"
                    />
                    <button onClick={handleAddDiscount} className="add-discount-button">AJOUTER</button>
                </>
            ) : (
                <p>Vous n'avez pas accès à cette page.</p>
            )}
        </div>
    );
};

export default AddDiscount;
