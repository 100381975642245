import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import { AuthProvider, AuthContext } from "./AuthContext";
import HomePage from "./HomePage"; // Import the HomePage
import Login from "./Login";
import Dashboard from "./Dashboard";
import Videos from "./Videos";
import Discounts from "./Discounts";
import PrivateRoute from "./PrivateRoute";
import Navbar from "./components/Navbar"; // Import the Navbar
import Footer from "./components/Footer"; // Import the Footer
import AddVideo from "./AddVideo";
import AddDiscount from "./AddDiscount";
import Users from "./Users";
import Creators from "./Creators";
import PageSettings from "./PageSettings";
import AllVideos from "./AllVideos";
import PrivacyPage from "./PrivacyPage";
import CGVPage from "./CGVPage";
import "./css/styles.css"; // Import the CSS file

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Main />
      </Router>
    </AuthProvider>
  );
};

const Main = () => {
  const { currentUser } = useContext(AuthContext);
  const location = useLocation(); // Get the current route

  return (
    <>
      {<Navbar />} {/* Show Navbar on all pages */}
      <Routes>
        <Route path="/" element={<HomePage />} /> {/* Set HomePage as the default route */}
        <Route path="/login" element={<Login />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/cgv" element={<CGVPage />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/videos"
          element={
            <PrivateRoute>
              <Videos />
            </PrivateRoute>
          }
        />
        <Route
          path="/addvideo"
          element={
            <PrivateRoute>
              <AddVideo />
            </PrivateRoute>
          }
        />
        <Route
          path="/allvideos"
          element={
            <PrivateRoute>
              <AllVideos />
            </PrivateRoute>
          }
        />
        <Route
          path="/discounts"
          element={
            <PrivateRoute>
              <Discounts />
            </PrivateRoute>
          }
        />
        <Route
          path="/adddiscount"
          element={
            <PrivateRoute>
              <AddDiscount />
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />
        <Route
          path="/creators"
          element={
            <PrivateRoute>
              <Creators />
            </PrivateRoute>
          }
        />
        <Route
          path="/pagesettings"
          element={
            <PrivateRoute>
              <PageSettings />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" />} /> {/* Redirect all unmatched routes to the HomePage */}
      </Routes>
      <Footer /> {/* Add Footer component to display it on all pages */}
    </>
  );
};

export default App;
