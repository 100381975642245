import React, { useState, useEffect, useContext, useCallback } from "react";
import { getFirestore, collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import { getAuth, getUser } from "firebase/auth"; // Import Firebase Auth functions
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import './css/styles.css';

function Users() {
    const [users, setUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const { currentUser, isSuperadmin } = useContext(AuthContext);
    const navigate = useNavigate();

    const fetchUsers = useCallback(async () => {
        if (!isSuperadmin) return;

        setLoading(true);
        const db = getFirestore();
        const auth = getAuth();
        const usersCollection = collection(db, "users");
        const usersQuery = query(usersCollection, orderBy("email"), limit(500)); // Adjust limit as necessary

        try {
            const usersSnapshot = await getDocs(usersQuery);
            const usersData = await Promise.all(
                usersSnapshot.docs.map(async (doc) => {
                    const data = doc.data();
                    let email = data.email;

                    // If email is missing, fetch it from Firebase Auth using the UID
                    if (!email) {
                        try {
                            const userRecord = await auth.getUser(doc.id); // Fetch user from Auth
                            email = userRecord.email || "No Email"; // Default to "No Email" if still missing
                        } catch (authError) {
                            console.error("Error fetching email from Firebase Auth:", authError);
                        }
                    }

                    return {
                        id: doc.id,
                        email,
                        is_creator: data.is_creator ?? false, // Default to false if not present
                        is_superadmin: data.is_superadmin ?? false, // Default to false if not present
                        total_views: data.total_views || 0,
                        total_clicks: data.total_clicks || 0
                    };
                })
            );

            console.log('Fetched Users:', usersData); // Debug: Check combined user data

            setAllUsers(usersData);
            setUsers(usersData);
        } catch (error) {
            console.error("Error fetching users:", error);
        } finally {
            setLoading(false);
        }
    }, [isSuperadmin]);

    useEffect(() => {
        if (currentUser && isSuperadmin) {
            fetchUsers();
        } else if (!currentUser) {
            navigate("/login"); // Redirect to login if no user is logged in
        }
    }, [currentUser, isSuperadmin, fetchUsers, navigate]);

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchQuery.trim()) {
            const filteredUsers = allUsers.filter(user =>
                user.email.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setUsers(filteredUsers);
        } else {
            setUsers(allUsers); // Reset to all users if the search query is empty
        }
    };

    return (
        <div className="container">
            {isSuperadmin ? (
                <>
                    <h2>Tous les utilisateurs</h2>
                    <form onSubmit={handleSearch} className="search-container">
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Recherche par email"
                            className="search-input"
                        />
                        <button type="submit" className="search-button">Rechercher</button>
                    </form>
                    <div className="user-list">
                        {users.map(user => (
                            <div key={user.id} className="user-item">
                                <h3>{user.email}</h3>
                                <p><strong>ID:</strong> {user.id}</p>
                                <p><strong>Créateur :</strong> {user.is_creator ? "Yes" : "No"}</p>
                                <p><strong>Superadmin :</strong> {user.is_superadmin ? "Yes" : "No"}</p>
                            </div>
                        ))}
                    </div>
                    {loading && <p>Chargement en cours...</p>}
                </>
            ) : (
                <p>Vous n'avez pas accès à cette page.</p>
            )}
        </div>
    );
}

export default Users;
