import React, { useState, useEffect, useContext, useCallback } from "react";
import { getFirestore, doc, getDoc, updateDoc, collection, query, where, getDocs, writeBatch } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import './css/styles.css';

const generateUniqueFilename = () => {
    const timestamp = Date.now();
    const randomString = Math.random().toString(36).substring(2, 15);
    return `${timestamp}_${randomString}.jpg`;
};

const resizeAndCropImage = (file, targetWidth, targetHeight) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        img.onload = () => {
            let sourceWidth = img.width;
            let sourceHeight = img.height;

            const targetRatio = targetWidth / targetHeight;
            const sourceRatio = sourceWidth / sourceHeight;

            let cropWidth, cropHeight, offsetX, offsetY;

            if (sourceRatio > targetRatio) {
                cropHeight = sourceHeight;
                cropWidth = cropHeight * targetRatio;
                offsetX = (sourceWidth - cropWidth) / 2;
                offsetY = 0;
            } else {
                cropWidth = sourceWidth;
                cropHeight = cropWidth / targetRatio;
                offsetX = 0;
                offsetY = (sourceHeight - cropHeight) / 2;
            }

            canvas.width = targetWidth;
            canvas.height = targetHeight;
            ctx.drawImage(img, offsetX, offsetY, cropWidth, cropHeight, 0, 0, targetWidth, targetHeight);

            canvas.toBlob(blob => {
                const resizedFile = new File([blob], file.name, { type: file.type });
                resolve(resizedFile);
            }, file.type);
        };

        img.onerror = (err) => {
            reject(err);
        };

        img.src = URL.createObjectURL(file);
    });
};

function PageSettings() {
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [creatorData, setCreatorData] = useState({
        hor_img_url: "",
        ver_img_url: "",
        title: "",
        bio: "",
        facebook_url: "",
        instagram_url: "",
        youtube_url: "",
        tiktok_url: "",
        linkedin_url: "",
        home_video_url: "",
        email_visible: false,
        lebavard_url: "",
        is_live: false,
        is_active: true,  // Assuming default true, will be set by fetched data
    });
    const [isSuspended, setIsSuspended] = useState(false);  // State to handle suspended status

    const fetchCreatorData = useCallback(async () => {
        try {
            const db = getFirestore();
            const creatorDocRef = doc(db, "creators", currentUser.uid);
            const creatorDoc = await getDoc(creatorDocRef);

            if (creatorDoc.exists()) {
                const data = creatorDoc.data();
                setCreatorData(data);

                // Check if the creator is active; if not, set suspended state
                if (!data.is_active) {
                    setIsSuspended(true);
                }
            } else {
                console.log("Creator document does not exist.");
            }
        } catch (error) {
            console.error("Error fetching creator data:", error);
        } finally {
            setLoading(false);
        }
    }, [currentUser]);

    useEffect(() => {
        if (currentUser) {
            fetchCreatorData();
        } else {
            navigate("/login");
        }
    }, [currentUser, navigate, fetchCreatorData]);

    const handleImageUpload = async (e, fieldName) => {
        const file = e.target.files[0];
        if (!file) return;

        const targetDimensions = fieldName === 'hor_img_url' ? { width: 900, height: 600 } : { width: 600, height: 900 };

        try {
            const resizedImage = await resizeAndCropImage(file, targetDimensions.width, targetDimensions.height);
            const storage = getStorage();
            const uniqueFilename = generateUniqueFilename();
            const imageRef = ref(storage, `creators/${currentUser.uid}/${uniqueFilename}`);
            const snapshot = await uploadBytes(imageRef, resizedImage);
            const imageUrl = await getDownloadURL(snapshot.ref);

            setCreatorData(prevData => ({
                ...prevData,
                [fieldName]: imageUrl,
            }));
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setCreatorData(prevData => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleSave = async () => {
        setLoading(true);
        const db = getFirestore();
        const creatorDocRef = doc(db, "creators", currentUser.uid);

        try {
            await updateDoc(creatorDocRef, creatorData);
            alert("Paramètres de la page enregistrés avec succès !");
        } catch (error) {
            console.error("Error saving page settings:", error);
            alert("Échec de l'enregistrement des paramètres de la page.");
        } finally {
            setLoading(false);
        }
    };

    const toggleIsLive = async () => {
        if (!creatorData.title || !creatorData.hor_img_url || !creatorData.ver_img_url || !creatorData.home_video_url) {
            alert("Veuillez remplir les champs requis (Titre, Images, Vidéo) avant de publier.");
            return;
        }

        const newIsLiveStatus = !creatorData.is_live;
        setCreatorData(prevData => ({ ...prevData, is_live: newIsLiveStatus }));

        const db = getFirestore();
        const creatorDocRef = doc(db, "creators", currentUser.uid);

        try {
            await updateDoc(creatorDocRef, { is_live: newIsLiveStatus });
            console.log("Creator live status updated:", newIsLiveStatus);

            // Fetch the creator's videos where creatorId or creator_id matches currentUser.uid
            const videosQuery1 = query(collection(db, "videos"), where("creatorId", "==", currentUser.uid));
            const videosQuery2 = query(collection(db, "videos"), where("creator_id", "==", currentUser.uid));

            const [videosSnapshot1, videosSnapshot2] = await Promise.all([
                getDocs(videosQuery1),
                getDocs(videosQuery2)
            ]);

            console.log("Videos from creatorId:", videosSnapshot1.docs.length);
            console.log("Videos from creator_id:", videosSnapshot2.docs.length);

            // Combine all video documents
            const allVideos = [...videosSnapshot1.docs, ...videosSnapshot2.docs];

            if (allVideos.length === 0) {
                console.log("No videos found for this creator.");
            } else {
                const batch = writeBatch(db);

                allVideos.forEach((videoDoc) => {
                    console.log("Updating video:", videoDoc.id);
                    batch.update(videoDoc.ref, { is_active: newIsLiveStatus });
                });

                await batch.commit();
                console.log("All related videos updated successfully.");
            }

            alert(newIsLiveStatus ? "Page publiée avec succès !" : "Page dépubliée avec succès !");
        } catch (error) {
            console.error("Error updating live status and videos:", error);
            alert("Échec de la mise à jour du statut de publication.");
        }
    };

    // Display suspension message if the account is inactive
    if (isSuspended) {
        return <p className="suspended-message">Votre compte a été suspendu.</p>;
    }

    return (
        <div className="container">
            <div className="page-settings-header">
                <h2>Paramètres de ta page</h2>
                <div className="button-group">
                    <button onClick={toggleIsLive} className="publish-button">
                        {creatorData.is_live ? "Dépublier" : "Publier"}
                    </button>
                    <button onClick={handleSave} className="save-button">Enregistrer</button>
                </div>
            </div>

            <p>Les infos que tu vas saisir ci-dessous seront utilisées pour générer ta page créateur sur l'appli Eh Salut. Quand tu es prête ou prêt, cliques sur le bouton publier et ta page sera Live. Je te conseille aussi d'ajouter quelques vidéos avant de publier.</p>

            {loading ? (
                <p>Chargement...</p>
            ) : (
                <form className="page-settings-form">
                    <div className="form-group">
                        <label>Image Horizontale (900x600) (requis)</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageUpload(e, "hor_img_url")}
                        />
                        {creatorData.hor_img_url && <img src={creatorData.hor_img_url} alt="Horizontal" className="preview-image" style={{ width: '200px' }} />}
                    </div>

                    <div className="form-group">
                        <label>Image Verticale (600x900) (requis)</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageUpload(e, "ver_img_url")}
                        />
                        {creatorData.ver_img_url && <img src={creatorData.ver_img_url} alt="Vertical" className="preview-image" style={{ width: '200px' }} />}
                    </div>

                    <div className="form-group">
                        <label>Script Le Bavard</label>
                        <input
                            type="text"
                            name="lebavard_url"
                            value={creatorData.lebavard_url}
                            onChange={handleInputChange}
                            placeholder="Code à copier coller depuis ton compte lebavard.fr"
                        />
                        <a href="https://lebavard.fr" target="_blank" rel="noopener noreferrer" className="learn-more-link">
                            En savoir plus cette IA qui peut répondre à ta place aux questions de tes abonnés.
                        </a>
                    </div>

                    <div className="form-group">
                        <label>Titre de ta page (requis)</label>
                        <input
                            type="text"
                            name="title"
                            value={creatorData.title}
                            onChange={handleInputChange}
                            placeholder="Titre de la page"
                        />
                    </div>

                    <div className="form-group">
                        <label>Biographie</label>
                        <textarea
                            name="bio"
                            value={creatorData.bio}
                            onChange={handleInputChange}
                            placeholder="Ta biographie"
                        ></textarea>
                    </div>

                    <div className="form-group">
                        <label>URL de la vidéo en vedette (requis)</label>
                        <input
                            type="text"
                            name="home_video_url"
                            value={creatorData.home_video_url}
                            onChange={handleInputChange}
                            placeholder="Lien vers ta vidéo d'intro"
                        />
                    </div>

                    <div className="form-group">
                        <label>Lien Facebook</label>
                        <input
                            type="text"
                            name="facebook_url"
                            value={creatorData.facebook_url}
                            onChange={handleInputChange}
                            placeholder="Lien vers ton profil Facebook"
                        />
                    </div>

                    <div className="form-group">
                        <label>Lien Instagram</label>
                        <input
                            type="text"
                            name="instagram_url"
                            value={creatorData.instagram_url}
                            onChange={handleInputChange}
                            placeholder="Lien vers ton profil Instagram"
                        />
                    </div>

                    <div className="form-group">
                        <label>Lien YouTube</label>
                        <input
                            type="text"
                            name="youtube_url"
                            value={creatorData.youtube_url}
                            onChange={handleInputChange}
                            placeholder="Lien vers ta chaîne YouTube"
                        />
                    </div>

                    <div className="form-group">
                        <label>Lien TikTok</label>
                        <input
                            type="text"
                            name="tiktok_url"
                            value={creatorData.tiktok_url}
                            onChange={handleInputChange}
                            placeholder="Lien vers ton profil TikTok"
                        />
                    </div>

                    <div className="form-group">
                        <label>Lien LinkedIn</label>
                        <input
                            type="text"
                            name="linkedin_url"
                            value={creatorData.linkedin_url}
                            onChange={handleInputChange}
                            placeholder="Lien vers ton profil LinkedIn"
                        />
                    </div>

                    <div className="form-group">
                        <label>
                            <input
                                type="checkbox"
                                name="email_visible"
                                checked={creatorData.email_visible}
                                onChange={handleInputChange}
                            />
                            Afficher ton adresse e-mail sur la page
                        </label>
                    </div>
                </form>
            )}
        </div>
    );
}

export default PageSettings;
