import React, { useState, useEffect, useContext, useCallback } from "react";
import {
    getFirestore,
    collection,
    getDocs,
    doc,
    deleteDoc,
    query,
    where,
    addDoc,
    getDoc,
    updateDoc,
    arrayRemove
} from "firebase/firestore";
import { AuthContext } from "./AuthContext";
import Modal from "react-modal";


const Videos = () => {
    const [categories, setCategories] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isVideoDeleteModalOpen, setIsVideoDeleteModalOpen] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState("");
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    const [videoToDelete, setVideoToDelete] = useState(null);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [videos, setVideos] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [isSuperadmin, setIsSuperadmin] = useState(false);
    const { currentUser } = useContext(AuthContext);

    const fetchCategories = useCallback(async () => {
        const db = getFirestore();
        const categoriesCollection = collection(db, "video_categories");
        const categoriesSnapshot = await getDocs(categoriesCollection);
        const categoriesList = categoriesSnapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name,
        }));
        setCategories(categoriesList);
    }, []);

    const checkSuperadminStatus = useCallback(async () => {
        if (!currentUser) return;

        const db = getFirestore();
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists() && userDoc.data().is_superadmin) {
            setIsSuperadmin(true);
            fetchCategories();
        } else {
            setIsSuperadmin(false);
        }
    }, [currentUser, fetchCategories]);

    const fetchVideos = useCallback(async () => {
        if (!currentUser) return;

        setLoading(true);
        try {
            const db = getFirestore();
            const creatorDocRef = doc(db, "creators", currentUser.uid);
            const creatorDoc = await getDoc(creatorDocRef);

            if (creatorDoc.exists()) {
                const videoIds = creatorDoc.data().videos || [];

                // Fetch videos from the root 'videos' collection based on IDs
                const videoDocs = await Promise.all(
                    videoIds.map(async (id) => {
                        const videoDoc = await getDoc(doc(db, "videos", id));
                        return videoDoc.exists() ? { id: videoDoc.id, ...videoDoc.data() } : null;
                    })
                );

                const validVideos = videoDocs.filter((video) => video !== null);
                setVideos(validVideos);
            }
        } catch (error) {
            console.error("Error fetching videos:", error);
        } finally {
            setLoading(false);
        }
    }, [currentUser]);

    useEffect(() => {
        if (currentUser) {
            checkSuperadminStatus();
            fetchVideos();
        }
    }, [currentUser, fetchVideos, checkSuperadminStatus]);

    const handleSearch = () => {
        fetchVideos();
    };

    const openAddModal = () => {
        setIsAddModalOpen(true);
    };

    const closeAddModal = () => {
        setIsAddModalOpen(false);
    };

    const openDeleteModal = (category) => {
        setCategoryToDelete(category);
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setCategoryToDelete(null);
        setIsDeleteModalOpen(false);
    };

    const openVideoDeleteModal = (video) => {
        setVideoToDelete(video);
        setIsVideoDeleteModalOpen(true);
    };

    const closeVideoDeleteModal = () => {
        setVideoToDelete(null);
        setIsVideoDeleteModalOpen(false);
    };

    const handleSaveCategory = async () => {
        if (newCategoryName.trim() === "") {
            alert("Category name cannot be empty.");
            return;
        }

        const db = getFirestore();
        const categoriesCollection = collection(db, "video_categories");

        try {
            await addDoc(categoriesCollection, {
                name: newCategoryName.trim(),
            });

            await fetchCategories(); // Refresh categories after adding a new one
            setNewCategoryName("");
            closeAddModal();
        } catch (error) {
            console.error("Error adding category: ", error);
        }
    };

    const handleDeleteCategory = async () => {
        if (!categoryToDelete) return;

        const db = getFirestore();
        const userVideosCollection = collection(db, "users", currentUser.uid, "videos");

        const videosQuery = query(userVideosCollection, where("categories", "array-contains", categoryToDelete.id));
        const videosSnapshot = await getDocs(videosQuery);

        if (videosSnapshot.size > 0) {
            alert("Tu peux pas ! Y a des vidéos dans cette catégorie.");
            closeDeleteModal();
            return;
        }

        const categoryDocRef = doc(db, "video_categories", categoryToDelete.id);
        await deleteDoc(categoryDocRef);

        await fetchCategories(); // Refresh categories after deletion
        closeDeleteModal();
    };

    const handleDeleteVideo = async () => {
        if (!videoToDelete) return;

        const db = getFirestore();
        const videoDocRef = doc(db, "videos", videoToDelete.id); // Reference to the video in the root 'videos' collection

        try {
            // Fetch the video document to get the creatorID
            const videoDoc = await getDoc(videoDocRef);
            if (videoDoc.exists()) {
                const videoData = videoDoc.data();
                const creatorID = videoData.creatorId; // Extract the creatorID from the video document

                // Step 1: Delete the video document from the root 'videos' collection
                await deleteDoc(videoDocRef);

                // Step 2: Remove the video ID from the creator's 'videos' array in the 'creators' collection
                if (creatorID) {
                    const creatorDocRef = doc(db, "creators", creatorID);
                    await updateDoc(creatorDocRef, {
                        videos: arrayRemove(videoToDelete.id),
                    });
                }

                // Step 3: Check and remove the video ID from the 'latest_videos' collection in the 'videos' document
                const latestVideosDocRef = doc(db, "latest_videos", "videos");
                const latestVideosDoc = await getDoc(latestVideosDocRef);
                if (latestVideosDoc.exists()) {
                    const latestVideosArray = latestVideosDoc.data().video_id || [];
                    if (latestVideosArray.includes(videoToDelete.id)) {
                        await updateDoc(latestVideosDocRef, {
                            video_id: arrayRemove(videoToDelete.id),
                        });
                    }
                }

                // Step 4: Check and remove the video ID from the 'featured_videos' collection in the 'videos' document
                const featuredVideosDocRef = doc(db, "featured_videos", "videos");
                const featuredVideosDoc = await getDoc(featuredVideosDocRef);
                if (featuredVideosDoc.exists()) {
                    const featuredVideosArray = featuredVideosDoc.data().video_id || [];
                    if (featuredVideosArray.includes(videoToDelete.id)) {
                        await updateDoc(featuredVideosDocRef, {
                            video_id: arrayRemove(videoToDelete.id),
                        });
                    }
                }

                fetchVideos(); // Refresh the video list after deletion
                closeVideoDeleteModal(); // Close the delete confirmation modal
                alert("Vidéo supprimée avec succès !");
            } else {
                console.error("Video document not found.");
            }
        } catch (error) {
            console.error("Error deleting video: ", error);
            alert("Une erreur est survenue lors de la suppression de la vidéo.");
        }
    };



    const toggleAccordion = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };

    const loadMoreVideos = () => {
        fetchVideos(true);
    };

    return (
        <div className="container">
            {isSuperadmin && (
                <>
                    <h2>
                        <button onClick={toggleAccordion} className="accordion">
                            Catégories de vidéos
                            <span>{isAccordionOpen ? "▲" : "▼"}</span>
                        </button>
                    </h2>

                    <div className={`panel ${isAccordionOpen ? "open" : ""}`}>
                        <div className="categories-list">
                            {categories.map((category) => (
                                <div key={category.id} className="category-item">
                                    {category.name}
                                    <button onClick={() => openDeleteModal(category)} className="delete-button">
                                        ×
                                    </button>
                                </div>
                            ))}
                        </div>

                        <button onClick={openAddModal} className="add-button">
                            +
                        </button>
                    </div>
                </>
            )}

            {/* Search Field */}
            <div className="search-container">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Rechercher une vidéo..."
                    className="search-input"
                />
                <button onClick={handleSearch} className="search-button">
                    Rechercher
                </button>
            </div>

            {/* Video Listing */}
            <div className="video-list">
                {videos.map((video) => (
                    <div key={video.id} className="video-item">
                        <a href={video.video_url} target="_blank" rel="noopener noreferrer">
                            <img src={`https://img.youtube.com/vi/${video.video_url.split("v=")[1]}/0.jpg`} alt={video.title} />
                        </a>
                        <p>{video.title}</p>
                        {/* <p>Vues : {video.views}</p>
                        <p>Clics : {video.clicks}</p> */}
                        <button onClick={() => openVideoDeleteModal(video)} className="delete-video-button">
                            ×
                        </button>
                    </div>
                ))}
            </div>

            {/* Pagination */}
            <div className="pagination">
                {loading ? (
                    <p>Chargement...</p>
                ) : (
                    lastVisible && (
                        <button onClick={loadMoreVideos} className="load-more-button">
                            Charger plus
                        </button>
                    )
                )}
            </div>

            {/* Add Category Modal */}
            <Modal
                isOpen={isAddModalOpen}
                ariaHideApp={false}
                onRequestClose={closeAddModal}
                contentLabel="Add Category"
                style={modalStyles}
            >
                <h2>Ajouter une catégorie</h2>
                <input
                    className="category-input"
                    type="text"
                    value={newCategoryName}
                    onChange={(e) => setNewCategoryName(e.target.value)}
                    placeholder="Nom de la catégorie"
                />
                <button onClick={handleSaveCategory} className="save-button">
                    Enregistrer
                </button>
                <button onClick={closeAddModal} className="cancel-button">
                    Annuler
                </button>
            </Modal>

            {/* Delete Category Confirmation Modal */}
            <Modal
                isOpen={isDeleteModalOpen}
                ariaHideApp={false}
                onRequestClose={closeDeleteModal}
                contentLabel="Delete Category"
                style={modalStyles}
            >
                <h2>T'es sûr que tu veux la virer ?</h2>
                <button onClick={handleDeleteCategory} className="save-button">
                    ben oui
                </button>
                <button onClick={closeDeleteModal} className="cancel-button">
                    oups non
                </button>
            </Modal>

            {/* Delete Video Confirmation Modal */}
            <Modal
                isOpen={isVideoDeleteModalOpen}
                ariaHideApp={false}
                onRequestClose={closeVideoDeleteModal}
                contentLabel="Delete Video"
                style={modalStyles}
            >
                <h2>T'es sûr que tu veux dégager cette vidéo ?</h2>
                <button onClick={handleDeleteVideo} className="save-button">
                    Vazy
                </button>
                <button onClick={closeVideoDeleteModal} className="cancel-button">
                    Non
                </button>
            </Modal>
        </div>
    );
};

const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        padding: "20px",
        width: "300px",
        borderRadius: "10px",
    },
};

export default Videos;
