import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);

    const handleLogin = async (e) => {
        e.preventDefault();

        const result = await login(email, password);
        if (result === true) {
            navigate("/dashboard");
        } else if (typeof result === "string") {
            setError(result);  // Show the error message returned by the login function
        } else {
            setError("Invalid email or password.");
        }
    };

    return (
        <div className="login-wrapper">
            <div className="login-container">
                <h2>Eh Salut, Ô Créateur de contenu.</h2>
                <p className="subtitle">Connecte toi pour accéder au backoffice et gérer ton contenu ainsi que ta présentation sur l'appli Eh Salut.</p>
                {/* <h2>Connexion</h2> */}
                <form onSubmit={handleLogin}>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        className="login-input"
                        required
                    />
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        className="login-input"
                        required
                    />
                    <button type="submit" className="login-button">Connexion</button>
                    {error && <p className="login-error">{error}</p>}
                </form>
            </div>
        </div>
    );
};

export default Login;
