import React from 'react';
import { Link } from 'react-router-dom';
import '../css/styles.css'; // Make sure your styles file contains appropriate styling for the footer

function Footer() {
    return (
        <footer className="footer-container">
            <div className="footer-content">
                <p>© {new Date().getFullYear()} Tous droits réservés à Eh Salut.</p>
                <p>
                    Site web et application mobile développés par{' '}
                    <a href="https://alsasoft.fr" target="_blank" rel="noopener noreferrer">
                        Alsasoft
                    </a>.
                </p>
            </div>
            <div className="footer-menu">
                <Link to="/privacy" className="footer-link">
                    Vie Privée
                </Link>
                <Link to="/cgv" className="footer-link">
                    CGV
                </Link>
            </div>
        </footer>
    );
}

export default Footer;
