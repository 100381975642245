import React from 'react';
import './css/styles.css'; // Assurez-vous d'utiliser un fichier de style pour maintenir une cohérence

function CGVPage() {
    return (
        <div className="cgu-container">
            <h1>Conditions Générales d'Utilisation</h1>
            <p>En vigueur au 16/09/2024</p>

            <section>
                <p>
                    Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique des modalités de mise à disposition du site et des services par _______________ et de définir les conditions d’accès et d’utilisation des services par « l'Utilisateur ».
                </p>
                <p>
                    Les présentes CGU sont accessibles sur le site à la rubrique « CGU ».
                </p>
                <p>
                    Toute inscription ou utilisation du site implique l'acceptation sans aucune réserve ni restriction des présentes CGU par l’utilisateur. Lors de l'inscription sur le site via le Formulaire d’inscription, chaque utilisateur accepte expressément les présentes CGU en cochant la case précédant le texte suivant : « Je reconnais avoir lu et compris les CGU et je les accepte ».
                </p>
                <p>
                    En cas de non-acceptation des CGU stipulées dans le présent contrat, l'Utilisateur se doit de renoncer à l'accès des services proposés par le site.
                </p>
                <p>
                    Ehsalut.fr se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU.
                </p>
            </section>

            <section>
                <h2>Article 1 : Les mentions légales</h2>
                <p>
                    L’édition et la direction de la publication du site Ehsalut.fr est assurée par Aria Stéphane, domicilié 2 rue des prés 67370 Truchtersheim.
                </p>
                <p>Numéro de téléphone : 0760561996</p>
                <p>Adresse e-mail : Steph_aria@ehsalut.fr</p>
                <p>
                    L'hébergeur du site Ehsalut.fr est la société Akamai Technologies Sarl, dont le siège social est situé au 40/44 Rue Washington, 75008 Paris, France, avec le numéro de téléphone : 0156696200.
                </p>
            </section>

            <section>
                <h2>Article 2 : Accès au site</h2>
                <p>
                    Le site Ehsalut.fr permet à l'Utilisateur un accès gratuit aux services suivants : Hébergement serveur et application. Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.
                </p>
                <p>
                    L’Utilisateur non membre n'a pas accès aux services réservés. Pour cela, il doit s’inscrire en remplissant le formulaire. En acceptant de s’inscrire aux services réservés, l’Utilisateur membre s’engage à fournir des informations sincères et exactes concernant son état civil et ses coordonnées, notamment son adresse email.
                </p>
            </section>

            <section>
                <h2>Article 3 : Collecte des données</h2>
                <p>
                    Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.
                </p>
                <p>
                    En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur exerce ce droit :
                </p>
                <ul>
                    <li>Par mail à l'adresse email Contact@ehsalut.fr</li>
                    <li>Via un formulaire de contact</li>
                    <li>Via son espace personnel</li>
                </ul>
            </section>

            <section>
                <h2>Article 4 : Propriété intellectuelle</h2>
                <p>
                    Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur. La marque Ehsalut est une marque déposée par Aria Stéphane. Toute représentation et/ou reproduction et/ou exploitation partielle ou totale de cette marque, de quelque nature que ce soit, est totalement prohibée.
                </p>
            </section>

            <section>
                <h2>Article 5 : Responsabilité</h2>
                <p>
                    Les sources des informations diffusées sur le site Ehsalut.fr sont réputées fiables mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d'omissions. Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle.
                </p>
            </section>

            <section>
                <h2>Article 6 : Liens hypertextes</h2>
                <p>
                    Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira du site Ehsalut.fr. Ce dernier n’a pas de contrôle sur les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.
                </p>
            </section>

            <section>
                <h2>Article 7 : Cookies</h2>
                <p>
                    L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation. Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l’ordinateur de l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation du site Ehsalut.fr.
                </p>
            </section>

            <section>
                <h2>Article 8 : Publication par l’Utilisateur</h2>
                <p>
                    Le site permet aux membres de publier les contenus suivants : Vidéos concernant le domaine du bâtiment. Dans ses publications, le membre s’engage à respecter les règles de la Netiquette (règles de bonne conduite de l’internet) et les règles de droit en vigueur.
                </p>
            </section>

            <section>
                <h2>Article 9 : Droit applicable et juridiction compétente</h2>
                <p>
                    La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître.
                </p>
            </section>


        </div>
    );
}

export default CGVPage;
