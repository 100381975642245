import React, { useState, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { auth } from "../firebase";
import { AuthContext } from "../AuthContext";
import "../css/Navbar.css";

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { currentUser, isSuperadmin } = useContext(AuthContext);

    const handleLogout = () => {
        auth.signOut().then(() => {
            navigate("/login");
        });
        setIsMenuOpen(false);
    };

    const handleLoginClick = () => {
        navigate("/login");
        setIsMenuOpen(false);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className="navbar">
            <div className="nav-header">
                <Link to="/" className="nav-logo">
                    Eh Salut!
                </Link>
                <div className="nav-hamburger" onClick={toggleMenu}>
                    <div className={`hamburger ${isMenuOpen ? "open" : ""}`}></div>
                </div>
            </div>

            <ul className={`nav-links ${isMenuOpen ? "active" : ""}`}>
                {currentUser ? (
                    <>
                        <li>
                            <Link
                                to="/dashboard"
                                className={location.pathname === "/dashboard" ? "active-link" : "link"}
                                onClick={() => setIsMenuOpen(false)}
                            >
                                Dashboard
                            </Link>
                        </li>

                        <li className="video-menu">
                            <button className="video-menu-button">Vidéos</button>
                            <ul className="video-submenu">
                                {isSuperadmin && (
                                    <li>
                                        <Link
                                            to="/allvideos"
                                            className={location.pathname === "/allvideos" ? "active-link" : "link"}
                                            onClick={() => setIsMenuOpen(false)}
                                        >
                                            Toutes les vidéos
                                        </Link>
                                    </li>
                                )}
                                <li>
                                    <Link
                                        to="/videos"
                                        className={location.pathname === "/videos" ? "active-link" : "link"}
                                        onClick={() => setIsMenuOpen(false)}
                                    >
                                        Mes vidéos
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/addvideo"
                                        className={location.pathname === "/addvideo" ? "active-link" : "link"}
                                        onClick={() => setIsMenuOpen(false)}
                                    >
                                        Ajouter une vidéo
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        {isSuperadmin && (
                            <>
                                <li>
                                    <Link
                                        to="/discounts"
                                        className={location.pathname === "/discounts" ? "active-link" : "link"}
                                        onClick={() => setIsMenuOpen(false)}
                                    >
                                        Discounts
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/adddiscount"
                                        className={location.pathname === "/adddiscount" ? "active-link" : "link"}
                                        onClick={() => setIsMenuOpen(false)}
                                    >
                                        +discount
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/users"
                                        className={location.pathname === "/users" ? "active-link" : "link"}
                                        onClick={() => setIsMenuOpen(false)}
                                    >
                                        Abonnés
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/creators"
                                        className={location.pathname === "/creators" ? "active-link" : "link"}
                                        onClick={() => setIsMenuOpen(false)}
                                    >
                                        Créateurs
                                    </Link>
                                </li>
                            </>
                        )}
                        <li>
                            <Link
                                to="/pagesettings"
                                className={location.pathname === "/pagesettings" ? "active-link" : "link"}
                                onClick={() => setIsMenuOpen(false)}
                            >
                                Page
                            </Link>
                        </li>
                        <li>
                            <button onClick={handleLogout} className="logout-button">
                                Déconnexion
                            </button>
                        </li>
                    </>
                ) : (
                    // Show "Connexion" button within the menu on mobile
                    <li>
                        <button onClick={handleLoginClick} className="login-button">
                            Connexion
                        </button>
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default Navbar;
