import React, { useState, useEffect, useContext, useCallback } from "react";
import { getFirestore, collection, getDocs, doc, updateDoc, setDoc, query, where, writeBatch } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { AuthContext } from "./AuthContext";
import './css/styles.css';

function Creators() {
    const [creators, setCreators] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedCreator, setSelectedCreator] = useState(null);
    const [newCreatorEmail, setNewCreatorEmail] = useState("");
    const [addCreatorError, setAddCreatorError] = useState("");

    const { currentUser, isSuperadmin } = useContext(AuthContext);
    const navigate = useNavigate();

    const fetchCreators = useCallback(async () => {
        if (!isSuperadmin) return;

        setLoading(true);
        const db = getFirestore();
        const creatorsCollection = collection(db, "creators");
        const creatorsSnapshot = await getDocs(creatorsCollection);

        const creatorsData = await Promise.all(
            creatorsSnapshot.docs.map(async (creatorDoc) => {
                const creatorId = creatorDoc.id;
                const creatorData = creatorDoc.data();

                const creatorName = creatorData.title || "Unknown";
                const videosArray = creatorData.videos || [];
                const videoCount = videosArray.length;

                return {
                    id: creatorId,
                    name: creatorName,
                    email: creatorData.email || "Unknown",
                    videoCount,
                    isActive: creatorData.is_active || false,
                };
            })
        );

        setCreators(creatorsData);
        setLoading(false);
    }, [isSuperadmin]);

    useEffect(() => {
        if (currentUser && isSuperadmin) {
            fetchCreators();
        } else if (!currentUser) {
            navigate("/login");
        }
    }, [currentUser, isSuperadmin, fetchCreators, navigate]);

    const openModal = (creator) => {
        setSelectedCreator(creator);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setSelectedCreator(null);
        setModalIsOpen(false);
    };

    const confirmToggleStatus = async () => {
        if (!selectedCreator) return;

        const db = getFirestore();
        const creatorDocRef = doc(db, "creators", selectedCreator.id);

        try {
            // Toggle the creator's is_active status
            const newIsActiveStatus = !selectedCreator.isActive;
            await updateDoc(creatorDocRef, {
                is_active: newIsActiveStatus,
            });

            // Fetch the creator's videos using both creatorId and creator_id fields
            const videosQuery1 = query(collection(db, "videos"), where("creatorId", "==", selectedCreator.id));
            const videosQuery2 = query(collection(db, "videos"), where("creator_id", "==", selectedCreator.id));

            const [videosSnapshot1, videosSnapshot2] = await Promise.all([
                getDocs(videosQuery1),
                getDocs(videosQuery2)
            ]);

            const allVideos = [...videosSnapshot1.docs, ...videosSnapshot2.docs];

            if (allVideos.length > 0) {
                const batch = writeBatch(db);

                allVideos.forEach((videoDoc) => {
                    console.log("Updating video status for:", videoDoc.id);
                    batch.update(videoDoc.ref, { is_active: newIsActiveStatus });
                });

                await batch.commit();
                console.log("All videos status updated successfully.");
            } else {
                console.log("No videos found for this creator.");
            }

            // Update the local state to reflect the change
            setCreators(prevCreators =>
                prevCreators.map(creator =>
                    creator.id === selectedCreator.id
                        ? { ...creator, isActive: newIsActiveStatus }
                        : creator
                )
            );

            closeModal();
            alert(newIsActiveStatus ? "Créateur réactivé avec succès !" : "Créateur suspendu avec succès !");
        } catch (error) {
            console.error("Error updating status and videos:", error);
            alert("Échec de la mise à jour du statut du créateur.");
        }
    };

    const handleAddCreator = async () => {
        setAddCreatorError("");
        if (!newCreatorEmail.trim()) {
            setAddCreatorError("L'email est requis.");
            return;
        }

        const db = getFirestore();
        const usersCollection = collection(db, "users");
        const usersSnapshot = await getDocs(usersCollection);
        const userDoc = usersSnapshot.docs.find(doc => doc.data().email === newCreatorEmail.trim());

        if (userDoc) {
            const userId = userDoc.id;

            await updateDoc(doc(db, "users", userId), { is_creator: true });
            await setDoc(doc(db, "creators", userId), {
                is_active: true,
                is_live: false,
            });

            fetchCreators();
            setNewCreatorEmail("");
        } else {
            setAddCreatorError("Utilisateur introuvable.");
        }
    };

    return (
        <div className="container">
            {isSuperadmin ? (
                <>
                    <h2>Tous les créateurs</h2>

                    <div className="add-creator-section">
                        <h3>Ajouter un créateur</h3>
                        <input
                            type="text"
                            value={newCreatorEmail}
                            onChange={(e) => setNewCreatorEmail(e.target.value)}
                            placeholder="Email de l'utilisateur"
                            className="search-input"
                        />
                        <button onClick={handleAddCreator} className="add-button">Rendre Créateur</button>
                        {addCreatorError && <p className="error-message">{addCreatorError}</p>}
                    </div>

                    <div className="user-list">
                        {creators.map(creator => (
                            <div key={creator.id} className="user-item">
                                <p><strong>ID:</strong> {creator.id}</p>
                                <p><strong>Nom :</strong> {creator.name}</p>
                                <p><strong>Email :</strong> {creator.email}</p>
                                <p><strong>Nombre de vidéos :</strong> {creator.videoCount}</p>
                                <p>
                                    <strong>Statut :</strong>{" "}
                                    {creator.isActive ? (
                                        <span style={{ color: "green" }}>ACTIF</span>
                                    ) : (
                                        <span style={{ color: "red" }}>SUSPENDU</span>
                                    )}
                                </p>
                                <button
                                    onClick={() => openModal(creator)}
                                    className="toggle-status-button"
                                >
                                    {creator.isActive ? "Suspendre" : "Réactiver"}
                                </button>
                            </div>
                        ))}
                    </div>
                    {loading && <p>Chargement en cours...</p>}
                </>
            ) : (
                <p>Vous n'avez pas accès à cette page.</p>
            )}

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Confirmation"
                className="modal"
                overlayClassName="modal-overlay"
            >
                <h2>
                    {selectedCreator?.isActive
                        ? `T'es sûr de vouloir suspendre ${selectedCreator?.name}? Sa page et ses vidéos ne seront plus visibles sur l'appli.`
                        : `Confirme que tu veux réactiver ${selectedCreator?.name} et le rendre visible sur ton appli.`}
                </h2>
                <button onClick={confirmToggleStatus} className="confirm-button">Oui</button>
                <button onClick={closeModal} className="cancel-button">Non</button>
            </Modal>
        </div>
    );
}

export default Creators;
