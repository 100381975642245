import React from 'react';
import './css/styles.css'; // Import your CSS file for styling
import stephImage from './img/steph.png'; // Import the image

const HomePage = () => {
    return (
        <div className="container">
            <main className="homepage-main">
                <section className="hero-section">
                    <div className="hero-content">
                        <h1>Bienvenue sur Eh Salut!</h1>
                        <p>
                            Eh Salut est une application mobile créée par
                            Stéphane Aria. Cette plateforme révolutionnaire vous
                            permet d'accéder à tout le contenu des créateurs de contenu français sur
                            les réseaux sociaux. Organisez leurs publications par catégorie,
                            interagissez avec vos créatrices et créateurs préférés, et accédez à
                            des offres exclusives négociées par Stéphane Aria auprès de
                            nombreuses entreprises en France.
                        </p>
                        <button className="download-button">
                            Télécharger l'application
                        </button>
                        {/* Add the image here for mobile display */}
                        <div className="mobile-image">
                            <img
                                src={stephImage}
                                alt="Stéphane Aria"
                                className="aria-image"
                            />
                        </div>
                    </div>
                    {/* Keep this hidden on mobile */}
                    <div className="hero-image">
                        <img
                            src={stephImage}
                            alt="Stéphane Aria"
                            className="aria-image"
                        />
                    </div>
                </section>

                <section className="features-section">
                    <h2>Pourquoi utiliser Eh Salut ?</h2>
                    <div className="features">
                        <div className="feature-item">
                            <h3>Accès aux contenus des créatrices et créateurs</h3>
                            <p>
                                Rassemblez tout le contenu de vos créateurs préférés dans une
                                seule application. Organisez les publications par catégories et
                                ne manquez jamais une mise à jour.
                            </p>
                        </div>
                        <div className="feature-item">
                            <h3>Interagissez directement avec les créateurs</h3>
                            <p>
                                Connectez-vous avec vos créateurs préférés et commentez
                                directement sur leurs posts depuis Eh Salut !
                            </p>
                        </div>
                        <div className="feature-item">
                            <h3>Offres exclusives et bons plans</h3>
                            <p>
                                Accédez à des offres spéciales et à des réductions exclusives
                                négociées par Stéphane Aria auprès de grandes marques et
                                entreprises en France.
                            </p>
                        </div>
                    </div>
                </section>
            </main>

            <footer className="homepage-footer">
                <p>© {new Date().getFullYear()} Eh Salut. Tous droits réservés.</p>
            </footer>
        </div>
    );
};

export default HomePage;
