import React, { useState, useEffect, useContext, useCallback } from "react";
import { getFirestore, collection, getDocs, doc, getDoc } from "firebase/firestore";
import { AuthContext } from "./AuthContext";

function Dashboard() {
    const [userCount, setUserCount] = useState(0);
    const [platformVideoCount, setPlatformVideoCount] = useState(0);
    const [discountCount, setDiscountCount] = useState(0);
    const [platformTotalViews, setPlatformTotalViews] = useState(0);
    const [platformTotalClicks, setPlatformTotalClicks] = useState(0);

    const [userVideoCount, setUserVideoCount] = useState(0);
    const [userTotalViews, setUserTotalViews] = useState(0);
    const [userTotalClicks, setUserTotalClicks] = useState(0);

    const { currentUser, isSuperadmin } = useContext(AuthContext);

    const fetchData = useCallback(async () => {
        if (!currentUser) return;

        const db = getFirestore();

        if (isSuperadmin) {
            // Fetch platform-wide stats for superadmins
            const [usersSnapshot, videosSnapshot, discountsSnapshot, viewsDocSnap, clicksDocSnap] = await Promise.all([
                getDocs(collection(db, "users")),
                getDocs(collection(db, "videos")),
                getDocs(collection(db, "discounts")),
                getDoc(doc(db, "totals", "views")),
                getDoc(doc(db, "totals", "clicks"))
            ]);

            setUserCount(usersSnapshot.size);
            setPlatformVideoCount(videosSnapshot.size); // Count all videos in the platform
            setDiscountCount(discountsSnapshot.size);

            if (viewsDocSnap.exists()) {
                setPlatformTotalViews(viewsDocSnap.data().views);
            }

            if (clicksDocSnap.exists()) {
                setPlatformTotalClicks(clicksDocSnap.data().clicks);
            }
        }

        // Fetch user-specific stats
        const creatorDocRef = doc(db, "creators", currentUser.uid);
        const creatorDoc = await getDoc(creatorDocRef);

        if (creatorDoc.exists()) {
            const creatorData = creatorDoc.data();

            setUserTotalViews(creatorData.total_views || 0);
            setUserTotalClicks(creatorData.total_clicks || 0);

            // Count the number of videos in the user's `videos` array
            const userVideoCount = Array.isArray(creatorData.videos) ? creatorData.videos.length : 0;
            setUserVideoCount(userVideoCount);
        }
    }, [currentUser, isSuperadmin]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className="dashboard-container">
            <div className="dashboard-header">
                <h1 className="dashboard-title">Tes stats</h1>
            </div>
            {isSuperadmin && (
                <>
                    <h2 className="dashboard-subtitle">Les stats de la plateforme</h2>
                    <div className="dashboard-stats">
                        <div className="dashboard-card">
                            <h3>Utilisateurs</h3>
                            <p>{formatNumber(userCount)}</p>
                        </div>
                        <div className="dashboard-card">
                            <h3>Vidéos</h3>
                            <p>{formatNumber(platformVideoCount)}</p>
                        </div>
                        <div className="dashboard-card">
                            <h3>Discounts</h3>
                            <p>{formatNumber(discountCount)}</p>
                        </div>
                        {/* <div className="dashboard-card">
                            <h3>Nombre de vues</h3>
                            <p>{formatNumber(platformTotalViews)}</p>
                        </div>
                        <div className="dashboard-card">
                            <h3>Nombre de clicks</h3>
                            <p>{formatNumber(platformTotalClicks)}</p>
                        </div> */}
                    </div>
                </>
            )}
            <h2 className="dashboard-subtitle">Tes stats personnelles</h2>
            <div className="dashboard-stats">
                <div className="dashboard-card">
                    <h3>Nombre de vidéos</h3>
                    <p>{formatNumber(userVideoCount)}</p>
                </div>
                {/* <div className="dashboard-card">
                    <h3>Nombre de vues</h3>
                    <p>{formatNumber(userTotalViews)}</p>
                </div>
                <div className="dashboard-card">
                    <h3>Nombre de clicks</h3>
                    <p>{formatNumber(userTotalClicks)}</p>
                </div> */}
            </div>
        </div>
    );
}

function formatNumber(number) {
    return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(number).replace(/,/g, ' ');
}

export default Dashboard;
