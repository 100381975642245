import React from 'react';
import './css/styles.css'; // Assurez-vous d'avoir un fichier de style pour garder une cohérence

function PrivacyPage() {
    return (
        <div className="privacy-container">
            <h1>Politique de Confidentialité</h1>
            <p>Application mobile Ehsalut</p>

            <section>
                <h2>ARTICLE 1 : PRÉAMBULE</h2>
                <p>
                    La présente politique de confidentialité a pour but d’informer les utilisateurs de l’application mobile sur la manière dont sont collectées leurs données personnelles. Sont considérées comme des données personnelles, toute information permettant d’identifier un utilisateur. À ce titre, il peut s’agir : de ses noms et prénoms, de son âge, de son adresse postale ou email, de sa localisation ou encore de son adresse IP (liste non-exhaustive).
                </p>
                <p>Cette politique complète les mentions légales et les Conditions Générales d’Utilisation consultables par les utilisateurs à l’adresse ehsalut.fr/cgu.</p>
            </section>

            <section>
                <h2>ARTICLE 2 : PRINCIPES RELATIFS À LA COLLECTE ET AU TRAITEMENT DES DONNÉES PERSONNELLES</h2>
                <p>
                    Conformément à l’article 5 du Règlement européen 2016/679, les données à caractère personnel sont :
                </p>
                <ul>
                    <li>Traitées de manière licite, loyale et transparente au regard de la personne concernée.</li>
                    <li>Collectées pour des finalités déterminées, explicites et légitimes, et ne pas être traitées ultérieurement d'une manière incompatible avec ces finalités.</li>
                    <li>Adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées.</li>
                    <li>Exactes et, si nécessaire, tenues à jour.</li>
                    <li>Conservées sous une forme permettant l'identification des personnes concernées pendant une durée n'excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont traitées.</li>
                    <li>Traitées de façon à garantir une sécurité appropriée des données collectées.</li>
                </ul>
            </section>

            <section>
                <h2>ARTICLE 3 : DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES DANS LE CADRE DE LA NAVIGATION SUR L’APPLICATION</h2>
                <h3>Article 3.1 : Données collectées</h3>
                <p>Les données personnelles collectées dans le cadre de notre activité sont les suivantes : adresse email, pseudo.</p>
                <p>La collecte et le traitement de ces données répond à la (aux) finalité(s) suivante(s) :</p>
                <ul>
                    <li>Gestion de l’espace membre</li>
                    <li>Gestion de la publication des avis</li>
                </ul>
                <h3>Article 3.2 : Hébergement des données</h3>
                <p>L’application ehsalut.fr est hébergée par Akamai Technologies, 40-44 Rue Washington, 75008 Paris, téléphone : 01 56 69 62 00.</p>
            </section>

            <section>
                <h2>ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES DONNÉES ET DÉLÉGUÉ À LA PROTECTION DES DONNÉES</h2>
                <h3>Article 4.1 : Le responsable du traitement des données</h3>
                <p>Les données à caractère personnel sont collectées par Ehsalut. Contact par mail : contact@ehsalut.fr.</p>
                <h3>Article 4.2 : Le délégué à la protection des données</h3>
                <p>Le délégué à la protection des données est Stéphane Aria, 2 rue des prés 67670 Truchtersheim, France, email steph_aria@ehsalut.fr.</p>
                <p>Si vous estimez que vos droits ne sont pas respectés, vous pouvez adresser une réclamation à la CNIL.</p>
            </section>

            <section>
                <h2>ARTICLE 5 : LES DROITS DE L’UTILISATEUR EN MATIÈRE DE COLLECTE ET DE TRAITEMENT DES DONNÉES</h2>
                <p>Tout utilisateur concerné par le traitement de ses données personnelles peut se prévaloir des droits suivants :</p>
                <ul>
                    <li>Droit d’accès, de rectification et droit à l’effacement des données.</li>
                    <li>Droit à la portabilité des données.</li>
                    <li>Droit à la limitation et à l’opposition du traitement des données.</li>
                    <li>Droit de ne pas faire l’objet d’une décision fondée exclusivement sur un procédé automatisé.</li>
                    <li>Droit de déterminer le sort des données après la mort.</li>
                    <li>Droit de saisir l’autorité de contrôle compétente.</li>
                </ul>
                <p>Pour exercer vos droits, veuillez adresser votre courrier à Ehsalut par e-mail à contact@ehsalut.fr.</p>
            </section>

            <section>
                <h2>ARTICLE 6 : CONDITIONS DE MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ</h2>
                <p>
                    L’éditeur de l’application Ehsalut se réserve le droit de pouvoir modifier la présente Politique à tout moment afin d’assurer aux utilisateurs de l’application sa conformité avec le droit en vigueur.
                </p>
                <p>La présente politique a été éditée le 16 septembre 2024.</p>
            </section>
        </div>
    );
}

export default PrivacyPage;
